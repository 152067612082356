<template>
  <layout-page>
    <template v-slot:toolbar-title>
      <v-toolbar-title>
        {{suitesConfig.getSuiteLabel(suiteId)}}
      </v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-toolbar-items>
        <v-btn
          text
          @click="$refs.schemaEditor.addNewAttribute()">
          <v-icon>add</v-icon>
          {{translate('Add attribute', 'add-attribute')}}
        </v-btn>

        <v-btn
          text
          :disabled="!canSave"
          @click="saveSchema()"
          >
          <v-icon>save</v-icon> {{translate('Save', 'save')}}
        </v-btn>
      </v-toolbar-items>
    </template>
    <schema-editor
      ref="schemaEditor"
      :loading="details.loading"
      :schema="details.schema"
      :disable-labels="false"
      :enable-keys="false"
      :getAttributeLabel="getAttributeLabel"
      @update-schema="schemaUpdate = $event"
      @update-config="configUpdate = $event"
      />
    <!--
    <pre><code>{{JSON.stringify({schema: this.schemaUpdate, config: this.configUpdate}, null, 2)}}</code></pre>
    -->
  </layout-page>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'
import SchemaEditor from '@/components/schemas/schema-editor.vue'

export default {
  props: ['suiteId'],
  data: () => ({
    mutations: 1,
    schemaUpdate: null,
    configUpdate: null
  }),
  components: {
    LayoutPage,
    SchemaEditor
  },
  computed: {
    ...mapGetters(['api', 'collectionsConfig', 'suitesConfig', 'translate']),
    canSave () {
      return !this.details.loading && (this.schemaUpdate || this.configUpdate)
    },
    getAttributeLabel () {
      return attribute => this.suitesConfig.getSuiteAttributeLabel(this.suiteId, attribute)
    }
  },
  asyncComputed: {
    details: {
      async get () {
        let schema = this.mutations && await this.api.suites.getSuiteSchema({suiteId: this.suiteId})
        // Get all collection schemas (including attributes), expanded with labels and sorting
        let collectionSchemas = await this.api.collections.getCollectionSchemas()
        return {
          collectionSchemas: this.collectionsConfig.mapCollectionSchemas(collectionSchemas),
          schema,
          loading: false
        }
      },
      default: {
        collectionSchemas: [],
        schema: {
          collectionId: null,
          suiteId: null,
          attributes: {}
        },
        loading: true
      }
    }
  },
  methods: {
    ...mapActions(['invalidateClientConfig']),
    async saveSchema() {
      let {schemaUpdate, configUpdate} = this
      if (!(schemaUpdate || configUpdate)) {
        return
      }
      if (schemaUpdate) {
        await this.api.suites.updateSuiteSchema({update: schemaUpdate})
      }
      if (configUpdate) {
        await this.api.clientSettings.updateClientSettings({
          update: {
            suites: {
              [this.suiteId]: {
                attributes: configUpdate
              }
            }
          }
        })
        await this.invalidateClientConfig()
      }

      this.mutations = this.mutations + 1
    }
  }
}
</script>
